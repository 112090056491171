/* eslint-disable */
<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <!-- Brand logo-->
      <b-link class="brand-logo auth-logo">
        <app-logo-svg />
      </b-link>
      <!-- /Brand logo-->
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <!-- Left Video-->
          <app-video />
        </div>
      </b-col>
      <!-- /Left Text-->
      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 vld-parent"
      >
        <b-button
          v-if="!displayLogin"
          variant="outline-none"
          class="login-back-btn"
          @click="showLogin"
        >
          <feather-icon
            icon="ChevronLeftIcon"
            size="16"
            class="mr-50"
          />
          Back
        </b-button>
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <template v-if="displayLogin">
            <b-card-title
              data-cy="login-title"
              class="mb-1 font-weight-bold"
              title-tag="h2"
            >
              Welcome to Kingpin! 👋
            </b-card-title>
            <b-card-text class="mb-2">
              Please sign-in to your account and start the adventure
            </b-card-text>
            <!-- form -->
            <div
              v-if="authErrorMessage"
              role="alert"
              :class="{ show: authErrorMessage }"
              class="alert fade alert-danger p-1"
            >
              <div class="alert-text">
                {{ authErrorMessage }}
              </div>
            </div>
            <validation-observer
              ref="loginForm"
              #default="{ invalid }"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="onSubmit"
              >
                <ul
                  v-if="!isLoginUsingPassword"
                  class="nav nav-tabs"
                >
                  <li
                    class="nav-item"
                    @click="isLoginByWhatsappNumber = false"
                  >
                    <a
                      class="nav-link"
                      :class="{ 'active': !isLoginByWhatsappNumber }"
                    >Email</a>
                  </li>
                  <li
                    class="nav-item"
                    @click="onClickWhatsAppTab"
                  >
                    <a
                      class="nav-link"
                      :class="{ 'active': isLoginByWhatsappNumber }"
                    >Whatsapp number</a>
                  </li>
                </ul>
                <!-- email -->
                <b-form-group
                  v-if="!isLoginByWhatsappNumber"
                  :label="isLoginUsingPassword ? 'Email' : ''"
                  label-for="login-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="login-email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Whatsapp number -->
                <b-form-group
                  v-else
                  label-for="account-phone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Whatsapp number"
                    rules="required"
                  >
                    <vue-tel-input
                      :value="phone"
                      :input-options="inputOptions"
                      mode="international"
                      name="phone"
                      :class="{ 'border-danger': errors.length > 0 }"
                      @input="onInputPhoneNumber"
                      @country-changed="onCountryChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="isNotValidPhoneNumber"
                      class="text-danger"
                    >Please enter the valid phone number</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <template v-if="isLoginUsingPassword">
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="login-password">Password</label>
                      <b-link :to="{ name: 'auth-forgot-password' }">
                        <small>Forgot Password?</small>
                      </b-link>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="login-password"
                          v-model="password"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="Password"
                          autocomplete="off"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <!-- checkbox -->
                  <b-form-group>
                    <b-form-checkbox
                      id="remember-me"
                      v-model="status"
                      name="checkbox-1"
                    >
                      Remember Me
                    </b-form-checkbox>
                  </b-form-group>
                  <!-- submit buttons -->
                  <b-button
                    type="submit"
                    variant="primary"
                    block
                    :disabled="invalid || loading"
                  >
                    Sign in
                    <feather-icon
                      v-if="loading"
                      icon="LoaderIcon"
                    />
                  </b-button>
                  <div class="text-center mt-1">
                    or get<span
                      class="color-text-black cursor-pointer"
                      @click="isLoginUsingPassword = false"
                    > sign in
                      link</span>
                  </div>
                </template>
                <template v-else>
                  <b-button
                    type="submit"
                    variant="primary"
                    block
                    class="mt-1"
                    :disabled="invalid || isNotValidPhoneNumber || loading"
                  >
                    Get sign in link
                    <feather-icon
                      v-if="loading"
                      icon="LoaderIcon"
                    />
                  </b-button>
                  <div class="text-center mt-1">
                    or enter <span
                      class="color-text-black cursor-pointer"
                      @click="onClickLoginByPassword()"
                    >the
                      password</span>
                  </div>
                </template>
              </b-form>
            </validation-observer>
            <b-card-text class="text-center mt-2">
              <span>New on our platform? </span>
              <b-link
                data-cy="create-account"
                :to="{ name: AUTH_REGISTER }"
              >
                <span>&nbsp;Create an account</span>
              </b-link>
            </b-card-text>
          </template>
          <template v-else>
            <b-card-title
              data-cy="login-title"
              class="mb-1 font-weight-bold text-center"
              title-tag="h2"
            >
              Welcome to Kingpin! 👋
            </b-card-title>
            <div class="text-center color- font-medium-2 mt-5">
              <template v-if="successMessage">
                <b-card-text class="my-2 font-weight-bolder">
                  Check your inbox.
                </b-card-text>
                <b-card-text>
                  Follow the link we sent to <br>
                  {{ userEmail || countryCode + phone }} to sign in.
                </b-card-text>
              </template>
              <b-card-text v-else>
                Our team is reviewing your registration details.<br> We will notify you via email as soon as your access
                has been approved
              </b-card-text>
              <b-button
                variant="primary"
                class="round"
                @click="showLogin"
              >
                Go Back
              </b-button>
            </div>
          </template>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
    <div
      v-if="isBannerVisible"
      id="banner"
    >
      <p id="cookies">
        We use cookies to ensure that we give you the best experience on our
        website. To find out more see our
        <a
          href="https://kingpin.global/privacy-policy.html"
          target="_blank"
        >Privacy Policy</a>
        <b-button
          class="button round"
          variant="light"
          @click="acceptCookie"
        >
          Accept
        </b-button>
      </p>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapGetters, mapState } from 'vuex'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser, getRedirectRouteData, setRedirectRouteData } from '@/auth/utils'
import {
  LOGIN, FETCH_PROFILE, SEND_LOGIN_LINK, AUTH_ERROR,
} from '@/store/modules/auth.module'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import { setCookies, getCookie } from '@/common-utils'
import analytics from '@/@core/utils/analytics'
import constants, { exProfileData, PORTAL_LINK, TOOLKIT_PAGE } from '@/constants'
import { formatObject, parseErrorObject } from '@/@core/utils/utils'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import AppLogoSvg from '@/@core/assets/svg-components/AppLogoSvg.vue'
import AppVideo from '@/assets/videos/AppVideo.vue'
import { FETCH_CART_COUNT } from '@/store/modules/shop.module'
import { AUTH_REGISTER } from '@/router/routes/pages'
import UserRoleMixin from '@/views/apps/UserRoleMixin.vue'

const { ROLES } = c

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    // BAlert,
    ValidationProvider,
    ValidationObserver,
    AppLogoSvg,
    AppVideo,
  },
  mixins: [togglePasswordVisibility, UserRoleMixin],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      countryCode: '',
      phone: '',
      loading: false,
      fullPage: false,
      authErrorMessage: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rules
      required,
      email,
      isBannerVisible: false,
      setCookies,
      getCookie,
      isLoginUsingPassword: false,
      isLoginByWhatsappNumber: false,
      displayLogin: true,
      successMessage: true,
      PORTAL_LINK,
      AUTH_REGISTER,
      isValidPhoneNumber: false,
      inputOptions: {
        id: 'login_whatsapp_number',
        placeholder: 'Enter whatsapp number',
      },
    }
  },
  computed: {
    ...mapGetters([AUTH_ERROR]),
    ...mapState({
      signupDetails: state => state.signup.signupDetails,
      isAdminSignUp: state => state.signup.isAdminSignUp,
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    isNotValidPhoneNumber() {
      return this.phone && !this.isValidPhoneNumber
    },
  },
  // this.$refs.loginForm.setErrors(error.response.data.error)
  watch: {
    authError(val) {
      this.authErrorMessage = val
    },
  },
  mounted() {
    if (this.getCookie('EU_COOKIE_LAW_CONSENT') == null) {
      this.isBannerVisible = true
    }
    if (this.isAdminSignUp) {
      this.displayLogin = false
      this.userEmail = this.signupDetails?.email
    }
    if (!this.isAdminSignUp && this.signupDetails?.email) {
      this.successMessage = false
      this.displayLogin = false
    }
  },
  methods: {
    onClickWhatsAppTab() {
      this.isLoginByWhatsappNumber = true
      this.isLoginUsingPassword = false
      analytics.track(constants.TRACKS.ACTIONS.CUSTOMER_CLICKS_WHATSAPP_TAB)
    },
    onCountryChanged(countryObject) {
      if (this.countryCode === `+${countryObject.dialCode}`) {
        return
      }
      analytics.track(constants.TRACKS.ACTIONS.CUSTOMER_CHANGED_COUNTRY_CODE_LOGIN_PAGE, {
        countryCode: countryObject.dialCode,
      })
    },
    onInputPhoneNumber(number, phoneObject) {
      this.phone = number && phoneObject.nationalNumber
      this.countryCode = `+${phoneObject.countryCallingCode}`
      this.isValidPhoneNumber = !!phoneObject?.valid
      if (this.isValidPhoneNumber) {
        analytics.track(constants.TRACKS.ACTIONS.CUSTOMER_FILLED_THE_NUMBER, {
          countryCode: phoneObject.countryCallingCode,
          phoneNumber: phoneObject.nationalNumber,
        })
      }
    },
    onClickLoginByPassword() {
      this.isLoginUsingPassword = true
      this.isLoginByWhatsappNumber = false
    },
    goToThePlatform() {
      const redirectRouteData = getRedirectRouteData()
      if (redirectRouteData) {
        // Redirect route
        this.$router.push(redirectRouteData)
        setRedirectRouteData(null)
      } else {
        // Dashboard
        this.$router.push(getHomeRouteForLoggedInUser(store.getters.entityType))
      }
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.loading = true
          setTimeout(() => {
            this.$store
              .dispatch(LOGIN, {
                email: this.userEmail,
                password: this.password,
              })
              .then(async data => {
                await this.$store.dispatch(FETCH_PROFILE).then(data => {
                  analytics.track(
                    constants.TRACKS.ACTIONS.LOGIN,
                    formatObject(data, exProfileData),
                  )
                })
                // Go to the paltform
                this.goToThePlatform()
                apiToastSuccess(
                  data.message || 'You have successfully logged in!',
                  'CoffeeIcon',
                )
                if (store.getters.entityType === ROLES.RETAILER) {
                  this.$store.dispatch(FETCH_CART_COUNT)
                }
                const profileData = this.$store.getters.profile
                if (profileData && profileData.shouldDisplayToolKit) {
                  this.$root.$refs.appTour.startTour(TOOLKIT_PAGE.WELCOME_TO_KINGPIN)
                }
              })
              .catch(message => {
                apiToastWarning(message)
                this.loading = false
              })
          }, 1000)
        }
      })
    },
    acceptCookie() {
      this.setCookies('EU_COOKIE_LAW_CONSENT', 30)
      this.isBannerVisible = false
    },
    onSubmit() {
      if (this.isLoginUsingPassword) {
        this.login()
      }
      else {
        this.sendLoginLink()
      }
    },
    sendLoginLink() {
      const payload = {}
      if (this.isLoginByWhatsappNumber) {
        payload.phone = this.phone
        payload.countryCode = this.countryCode
        if (this.isLoginByWhatsappNumber) {
          analytics.track(constants.TRACKS.ACTIONS.CUSTOMER_CLICKS_GET_SIGNIN_LINK_BUTTON, {
            countryCode: this.countryCode,
            phoneNumber: this.phone,
          })
        }
      } else {
        payload.email = this.userEmail
      }
      this.$store.dispatch(SEND_LOGIN_LINK, payload)
        .then(res => {
          apiToastSuccess(res.message)
          this.displayLogin = false
          if (this.isLoginByWhatsappNumber) {
            analytics.track(constants.TRACKS.ACTIONS.SENT_SIGNIN_LINK_TO_CUSTOMER, { countryCode: this.countryCode, phoneNumber: this.phone })
          }
        })
        .catch(err => {
          apiToastWarning(err)
          if (this.isLoginByWhatsappNumber) {
            analytics.track(constants.TRACKS.ACTIONS.CUSTOMER_GET_ERROR_MESSAGE_WHEN_SUBMIT, { message: parseErrorObject(err), countryCode: this.countryCode, phoneNumber: this.phone })
          }
        })
    },
    showLogin() {
      this.userEmail = null
      this.displayLogin = true
      this.successMessage = true
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/pages/cookie-banner.scss';

.vue-tel-input {
  height: 2.714rem;
}
</style>
